* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  background: #f1f1f1;
}

.canvas-row{
  height: 90vh
}

.angularbee{
  height: 100vh;
}

.color-picker{
  display: inline-block;
  height: 15px;
  width: 30px;
}
